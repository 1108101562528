import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore/';
// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { REGION } from '@angular/fire/compat/functions';

import { CUSTOM_DATE_FORMAT } from './shared/shared.module';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { GraphQLModule } from './graphql.module';
import { environment } from 'src/environments/environment';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	exports: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		// AngularFireAuthModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		// AngularFirestoreModule,
		// AngularFirePerformanceModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		GraphQLModule,
		// NgxGoogleAnalyticsModule.forRoot(environment.trackingCode),
		// NgxGoogleAnalyticsRouterModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
			defaultLanguage: 'en',
			isolate: false,
			extend: true,
		}),
	],
	providers: [
		{ provide: REGION, useValue: 'europe-west1' },
		// {
		// 	provide: ErrorHandler,
		// 	useValue: Sentry.createErrorHandler({
		// 		showDialog: false,
		// 	}),
		// },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
