import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { environment } from 'src/environments/environment';
import { AuthenticationService } from './core/services';

export function createApollo(httpLink: HttpLink, authenticationService: AuthenticationService): ApolloClientOptions<any> {
	const uri = environment.apiUrl;
	const wsUri = environment.wsUrl;

	const tokenLink = setContext(async (operation, context) => {
		if (!authenticationService.authenticated || operation.operationName === 'getTenantByDisplayName') {
			return {};
		} else {
			// refresh Token each time
			if (authenticationService.expired) {
				console.log('Token expired - getting a new one');
				await authenticationService.getNewToken();
			}
			const token = authenticationService.accessToken;

			return {
				headers: {
					Authorization: `Bearer ${token}`,
					client_id: `${token}`,
				},
			};
		}
	});

	const loggerLink = new ApolloLink((operation, forward) => {
		if (environment.debug) console.log(`GraphQL Request: ${operation.operationName}`);
		operation.setContext({ start: new Date() });
		return forward(operation).map((response) => {
			const responseTime = Math.abs(<any>new Date() - operation.getContext().start);
			if (environment.debug) console.log(`GraphQL Response for ${operation.operationName} took: ${responseTime} ms`);
			return response;
		});
	});

	const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				switch (err.message) {
					case 'UNAUTHENTICATED!':
						console.log('[graphQLErrors]:', graphQLErrors);
						return forward(operation);
				}
			}
		}

		if (networkError) {
			console.log(`[Network error]: ${JSON.stringify(networkError)}`);
		}
	});

	const http = httpLink.create({
		uri,
	});

	const wsLink = new GraphQLWsLink(
		createClient({
			url: wsUri,
		})
	);

	const link = split(
		({ query }) => {
			const data = getMainDefinition(query);
			return data.kind === 'OperationDefinition' && data.operation === 'subscription';
		},
		wsLink,
		http
	);

	return {
		link: ApolloLink.from([errorLink, loggerLink, tokenLink, link as any]) as any,
		cache: new InMemoryCache(),
	};
}

@NgModule({
	exports: [ApolloModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink, AuthenticationService],
		},
	],
})
export class GraphQLModule {}
