import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
	Booking,
	BookingFilter,
	BookingStatus,
	OrderByBooking,
	OrderDirection,
	Tag,
	Tenant,
	TenantCurrency,
} from 'src/app/core/graphql/generated/gen-types';
import { UserService } from 'src/app/core/services';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import {
	bookingColumnsOpen,
	bookingColumnsPayment,
	DisplayedColumn,
	bookingColumnsHistory,
	bookingColumnsDashboard,
	bookingColumnsFlight,
	bookingColumnsFlightAll,
	bookingColumnsFlightEnd,
	bookingColumnsFlightPayment,
} from '../../../features/booking/booking-displayedcolumns';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';

export type BookingTableType =
	| 'upcoming'
	| 'historical'
	| 'payment'
	| 'dashboard'
	| 'flight.onlyMatching'
	| 'flight.all'
	| 'bookings'
	| 'flight.payment';
@Component({
	selector: 'app-booking-table-is',
	templateUrl: './booking-table-is.component.html',
	styleUrls: ['./booking-table-is.component.scss'],
})
export class BookingTableIsComponent implements OnInit {
	@Input() type: string;
	@Input() filterValue: string = '';
	@Input() bookingFilter: BookingFilter;
	@Input() flightId: string;
	@Input() bookings: Booking[] = [];
	@Input() viewState: any;
	@Input() ended: boolean = false;
	@Input() selectedFilterTags: Tag[] = [];
	@Input() selectedFilterRegion: string;

	@Output() deleteBooking = new EventEmitter<Booking>();
	@Output() confirmBooking = new EventEmitter<Booking>();
	@Output() inviteBooking = new EventEmitter<Booking>();
	@Output() confirmMailBooking = new EventEmitter<Booking>();
	@Output() editFlight = new EventEmitter<any>();
	@Output() addBookingsToFlight = new EventEmitter<[Booking]>();
	@Output() removeBookingFromFlight = new EventEmitter<Booking>();
	@Output() confirmBookingToFlight = new EventEmitter<Booking>();
	@Output() showPaymentStatusBooking = new EventEmitter<Booking>();

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	datasourceBookings = null;
	customSortingDataAccessor;

	loadTrigger = 80; // percentage of scrollProgress to trigger a new load
	amountOfRequests = 1;
	bookingsLimit = 40;
	bookingSubs: Subscription[] = [];
	isLoadingBookings = true;
	loadingIndex = 0;
	screensize = 3;
	isMobile: boolean;
	tableHeight: number = 0;

	//Ordering & Sorting
	sortDirection: OrderDirection = OrderDirection.Desc;
	sortActive: OrderByBooking = OrderByBooking.BookingDate;
	sortActiveValues = Object.values(OrderByBooking);
	sortEvent = { active: this.sortActive, direction: this.sortDirection };

	cursor: string;
	endAt: boolean = false;

	allBookingsLoadedTop = false;
	allBookingsLoadedBottom = false;

	displayedColumnsParent: DisplayedColumn[] = [];

	@ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
	scrollProgress: number;
	bookingCursor: boolean = false;
	bookingUpdate: boolean = false;

	currency: TenantCurrency;

	constructor(
		private snackBar: SnackBar,
		private bookingService: BookingService,
		private responsiveService: ResponsiveService,
		private tagService: TagService,
		private router: Router,
		private userService: UserService,
		private tenantSettingService: TenantSettingService
	) {
		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			this.currency = result.data.tenantSetting?.operatorSettings?.currency;
		});

		this.responsiveService.viewMobileChanged.subscribe((mobileView) => {
			this.isMobile = mobileView;
		});
		this.responsiveService.screenSizeChanged.subscribe((screensize) => {
			this.screensize = screensize;
		});
		this.tableHeight = window.innerHeight - 290; // default full screen

		// region sorting dataAccessor
		this.customSortingDataAccessor = (item, property) => {
			switch (property) {
				case 'preferredDate':
					if (!item['region'] || !item['region']['name']) {
						return item['preferredDate'];
					} else {
						return item['preferredDate'] + item['region']['name'];
					}
				default:
					return item[property];
			}
		};

		this.bookingService.refreshSearchResults$.subscribe(() => {
			this.resetTable();
			this.getData();
		});
	}

	ngOnInit(): void {
		if (this.type.startsWith('flight')) {
			this.tableHeight = 600; // fixed height for flight bookings
		}
		if (this.type === 'dashboard' || this.type === 'bookings' || this.type === 'flight.payment') {
			this.tableHeight = null; // no fixed height for dashboard & bookings
		}
		if (this.type === 'dashboard' || this.type === 'bookings' || this.type.startsWith('flight')) {
			this.getData();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.viewState && changes.viewState.currentValue) {
			this.viewState = changes.viewState.currentValue;
			if (this.type === 'upcoming') {
				this.cursor = this.viewState.bookingList.open.cursor;
				this.sortActive = this.viewState.bookingList.open.sort.active;
				this.sortDirection = this.viewState.bookingList.open.sort.direction;
			}
			if (this.type === 'historical') {
				this.cursor = this.viewState.bookingList.history.cursor;
				this.sortActive = this.viewState.bookingList.history.sort.active;
				this.sortDirection = this.viewState.bookingList.history.sort.direction;
			}
			// backwards compatibility check - cursor should be a string
			if (typeof this.cursor !== 'string') {
				this.cursor = null;
			}
			// Do not reset the cursor but all the rest
			this.resetTable(false);
			this.getData();
		}
		if (changes.selectedFilterRegion) {
			this.resetTable();
			this.getData();
		}

		if (
			changes.selectedFilterTags &&
			changes.selectedFilterTags.currentValue !== changes.selectedFilterTags.previousValue &&
			!changes.selectedFilterTags.isFirstChange()
		) {
			this.resetTable();
			this.getData();
		}

		if (
			changes.filterValue &&
			changes.filterValue.currentValue !== changes.filterValue.previousValue &&
			!changes.filterValue.isFirstChange()
		) {
			this.filterValue = changes.filterValue.currentValue;
			this.resetTable();
			this.getData();
		}
		if (changes.type && changes.type.currentValue !== changes.type.previousValue && !changes.type.isFirstChange()) {
			this.resetTable();
			this.getData();
		}

		if (
			changes.bookingFilter &&
			changes.bookingFilter.currentValue !== changes.bookingFilter.previousValue &&
			!changes.bookingFilter.isFirstChange()
		) {
			this.resetTable();
			this.getData();
		}

		if (changes.bookings) {
			this.datasourceBookings = new MatTableDataSource(this.sortBookings(changes.bookings.currentValue));
			this.datasourceBookings.sortingDataAccessor = this.customSortingDataAccessor;
		}
		if (changes.ended) {
			this.resetTable();
			this.getData();
		}
	}

	ngOnDestroy(): void {
		// reset cursor when leaving the page
		// if (!this.bookingCursor) {
		this.cursor = null;
		// }

		// unsubscribe from all subscriptions
		if (this.bookingSubs.length > 0) {
			this.bookingSubs.forEach((sub) => sub.unsubscribe());
		}

		if (this.viewState) {
			if (this.type === 'upcoming' || this.type === 'payment') {
				this.viewState.bookingList.open = {
					cursor: this.cursor,
					sort: {
						active: this.sortActive,
						direction: this.sortDirection,
					},
				};
			} else if (this.type === 'historical') {
				this.viewState.bookingList.history = {
					cursor: this.cursor,
					sort: {
						active: this.sortActive,
						direction: this.sortDirection,
					},
				};
			}
			this.viewState.bookingList.filter = this.filterValue;
			this.userService.saveUserViewState(this.viewState).subscribe(
				() => {},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		const heightOfTable = event.target.innerHeight - 310;
		const heightOfRows = 49 * this.bookings.length;
		if (heightOfTable >= heightOfRows) {
			this.getData();
		}
	}

	sortBookings(bookings) {
		let sortedBookings = [];
		sortedBookings = sortedBookings.concat(bookings.filter((b) => b.status === BookingStatus.Comp));
		sortedBookings = sortedBookings.concat(bookings.filter((b) => b.status === BookingStatus.Conf));
		sortedBookings = sortedBookings.concat(bookings.filter((b) => b.status === BookingStatus.Invi));
		sortedBookings = sortedBookings.concat(bookings.filter((b) => b.status === BookingStatus.Adde));
		return sortedBookings;
	}

	private getData(): void {
		this.getAmountOfRequests();

		// get booking list depends on type
		if (this.type === 'upcoming') this.displayedColumnsParent = bookingColumnsOpen;
		if (this.type === 'historical') this.displayedColumnsParent = bookingColumnsHistory;
		if (this.type === 'payment') this.displayedColumnsParent = bookingColumnsPayment;
		if (this.type === 'flight.onlyMatching' || this.type === 'bookings') this.displayedColumnsParent = bookingColumnsFlight;
		if (this.type === 'bookings' && this.ended) this.displayedColumnsParent = bookingColumnsFlightEnd;
		if (this.type === 'flight.all') this.displayedColumnsParent = bookingColumnsFlightAll;
		if (this.type === 'dashboard') this.displayedColumnsParent = bookingColumnsDashboard;

		if (this.type === 'bookings') {
			this.datasourceBookings = new MatTableDataSource(this.sortBookings(this.bookings));
			this.datasourceBookings.sortingDataAccessor = this.customSortingDataAccessor;
			this.isLoadingBookings = false;
			return;
		}
		if (this.type === 'flight.payment') {
			this.displayedColumnsParent = bookingColumnsFlightPayment;
			this.datasourceBookings = new MatTableDataSource(this.bookings);
			this.datasourceBookings.sortingDataAccessor = this.customSortingDataAccessor;
			this.datasourceBookings.sort = this.sort;
			this.isLoadingBookings = false;
			return;
		}
		if (this.type === 'dashboard') {
			this.sortActive = null;
			this.bookingSubs.push(
				this.bookingService.getDashboardBookings().valueChanges.subscribe((result) => {
					this.bookings = result.data.dashboardBookings;
					this.datasourceBookings = new MatTableDataSource(this.bookings);
					this.datasourceBookings.sortingDataAccessor = this.customSortingDataAccessor;
					this.datasourceBookings.sort = this.sort;
					this.isLoadingBookings = false;
				})
			);
			return;
		}
		// in all other cases
		// set class in case type starts with flight
		if (this.type.startsWith('flight')) {
			this.sortDirection = OrderDirection.Desc;
			this.sortActive = OrderByBooking.BookingDate;
		}
		this.getBookingList();
	}

	private getBookingList() {
		this.isLoadingBookings = true;
		this.loadingIndex += 1;
		const index = this.loadingIndex;
		const filter: string[] = this.selectedFilterTags.map((tag) => this.tagService.addPrefix(tag)).concat(this.filterValue);
		if (this.selectedFilterRegion) filter.push(this.selectedFilterRegion);
		// in case of flight bookings, we need to add the flight id to the filter
		let flightId = null;
		if (this.type === 'flight.onlyMatching' || this.type === 'flight.all') {
			flightId = this.flightId;
		}

		// in case of onlyMatching, we need to add the flightOnlyMatching flag
		let flightOnlyMatching = null;
		if (this.type === 'flight.onlyMatching') flightOnlyMatching = true;

		const bookingSub = this.bookingService
			.getBookingsIS(
				this.type,
				this.bookingsLimit,
				this.cursor,
				filter,
				this.sortActive,
				this.sortDirection,
				this.bookingFilter,
				flightId,
				flightOnlyMatching,
				this.endAt
			)
			.valueChanges.subscribe(
				(result) => {
					if (index === this.loadingIndex || this.bookingUpdate) {
						this.bookingUpdate = false;
						this.bookings = this.bookings.concat(result.data.getBookingsIS);
						this.filterOutDuplicates();
						this.datasourceBookings = new MatTableDataSource(this.bookings);
						this.datasourceBookings.sortingDataAccessor = this.customSortingDataAccessor;
						this.datasourceBookings.sort = this.sort;
						if (this.bookings.length > 0) {
							this.cursor = this.bookings[this.bookings.length - 1][this.sortActive];
						}
						if (result.data.getBookingsIS.length < this.bookingsLimit) {
							this.allBookingsLoadedTop = this.endAt;
							this.allBookingsLoadedBottom = !this.endAt;
						}
						this.isLoadingBookings = false;
						if (this.amountOfRequests > 1) {
							this.amountOfRequests += -1;
							this.getBookingList();
						}
					}
				},
				(err) => {
					this.isLoadingBookings = false;
					this.snackBar.openSnackBarError(err.message);
				}
			);
		this.bookingSubs.push(bookingSub);
	}

	private getAmountOfRequests(): void {
		if (this.bookings.length != 0) {
			this.amountOfRequests = 1;
		} else {
			const heightOfRows = 49 * this.bookingsLimit; //49px is the fixed height of each column in the table
			this.amountOfRequests = Math.ceil(this.tableHeight / heightOfRows);
		}
	}

	private filterOutDuplicates() {
		// keep most recent version (reverse) of a flight in case of duplicates (filter)
		this.bookings = this.bookings.reverse().filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
		// sort by sortActive and sortDirection (string with localCompare but dates with < and >)
		if (this.sortActive === OrderByBooking.ContactName) {
			this.bookings = this.bookings.sort((a, b) => {
				return (a[this.sortActive] as string).localeCompare(b[this.sortActive] as string);
			});
		} else {
			this.bookings = this.bookings.sort((a, b) => {
				if (!a[this.sortActive]) return -1;
				if (a[this.sortActive] < b[this.sortActive]) {
					return this.sortDirection === 'asc' ? -1 : 1;
				}
				if (a[this.sortActive] > b[this.sortActive]) {
					return this.sortDirection === 'asc' ? 1 : -1;
				}
				return 0;
			});
		}
	}

	isScrollbar() {
		return this.scrollContainer.nativeElement.scrollHeight > this.scrollContainer.nativeElement.clientHeight;
	}

	private resetTable(cursor = true): void {
		// only reset cursor if cursor is true
		if (cursor) {
			this.cursor = null;
		}
		// if there is a scrollbar then scroll to top
		if (this.isScrollbar()) {
			this.scrollContainer.nativeElement.scrollTop = 0;
		}

		this.endAt = false;
		this.bookings = [];
		this.datasourceBookings = new MatTableDataSource(this.bookings);
		this.datasourceBookings.sortingDataAccessor = this.customSortingDataAccessor;
		this.allBookingsLoadedTop = false;
		this.allBookingsLoadedBottom = false;
		if (this.type === 'upcoming') {
			this.displayedColumnsParent = bookingColumnsOpen;
		} else if (this.type === 'payment') {
			this.displayedColumnsParent = bookingColumnsPayment;
		} else if (this.type === 'historical') {
			this.displayedColumnsParent = bookingColumnsHistory;
		}
	}

	getDisplayedColumns() {
		const up = this.displayedColumnsParent.find((col) => col.name === 'up');
		if (up) up.minSize = 5;
		return this.displayedColumnsParent.filter((col) => col.minSize <= this.screensize).map((col) => col.name);
	}

	bookingClicked(row) {
		this.cursor = row[this.sortActive];
		this.bookingCursor = true;
		this.navigateToBooking(row.id);
	}

	navigateToBooking(id?) {
		let url = '/booking';
		if (id) {
			url = `${url}/${id}`;
		}

		this.router.navigate([url]);
	}

	handleSortEvent(event) {
		if (this.type === 'dashboard') return;
		if (event.direction && this.sortDirection != event.direction) {
			this.sortDirection = event.direction;
		}
		if (event.active && this.sortActive != event.active) {
			this.sortActive = event.active;
		}
		// reset cursor && bookings
		this.resetTable();
		this.getData();
	}

	navigateToFlight(booking?) {
		// set bookingCursor
		let url = '/flight';
		if (booking?.flight?.id) {
			this.cursor = booking[this.sortActive];
			this.bookingCursor = true;
			url = `${url}/${booking.flight.id}`;
		}

		this.router.navigate([url]);
	}

	mergeCandidatesRed(booking: Booking): Boolean {
		// in case of mergeCandidates with flights
		if (booking.mergeCandidates && booking.mergeCandidates.length > 0) {
			return booking.mergeCandidates.filter((c) => c.flight?.date).length > 0;
		}
		return false;
	}

	mergeCandidatesYellow(booking: Booking): Boolean {
		// in case of mergeCandidates without flights
		if (booking.mergeCandidates && booking.mergeCandidates.length > 0) {
			return booking.mergeCandidates.filter((c) => !c.flight || !c.flight?.id).length > 0 && !this.mergeCandidatesRed(booking);
		}
		return false;
	}

	countAllBookings() {
		return this.bookingsNotOnFlight().length;
	}

	bookingsNotOnFlight() {
		return this.bookings.filter((booking) => booking.flight?.id != this.flightId);
	}

	updateComments(booking, event) {
		if (event) {
			this.bookingUpdate = true;
			const comments = event.save ? event.comments : booking.comments;
			const tags = event.save ? event.tags : booking.tags;
			if (event.save) {
				// update local datasourceBookings.data
				const bookingIndex = this.datasourceBookings.data.findIndex((b) => b.id === booking.id);
				this.datasourceBookings.data[bookingIndex].comments = comments;
				this.datasourceBookings.data[bookingIndex].tags = tags;
				// update comments in backend
				this.bookingSubs.push(
					this.bookingService.updateBookingComments(booking.id, comments).subscribe(
						() => {},
						(err) => this.snackBar.openSnackBarError(err.message)
					)
				);
				if (tags) {
					// update tags in backend
					this.bookingSubs.push(
						this.bookingService.updateBookingTags(booking.id, tags).subscribe(
							() => {},
							(err) => this.snackBar.openSnackBarError(err.message)
						)
					);
				}
			}
		}
	}

	updatePreferredFlights(booking, event) {
		if (event) {
			this.bookingUpdate = true;
			const preferredFlights = event.save ? event.preferredFlights : booking.preferredFlights;
			if (event.save) {
				// update local datasourceBookings.data
				const bookingIndex = this.datasourceBookings.data.findIndex((b) => b.id === booking.id);
				this.datasourceBookings.data[bookingIndex].preferredFlights = preferredFlights;

				// update preferred flights in backend
				this.bookingSubs.push(
					this.bookingService.updatePreferredFlights(booking.id, preferredFlights).subscribe(
						() => {},
						(err) => this.snackBar.openSnackBarError(err.message)
					)
				);
			}
		}
	}

	openUrl(url) {
		if (url) {
			window.open(url);
		}
	}

	scrollHandler(e): void {
		let scrollTop = false;
		// detect scroll top
		if (e.srcElement.scrollTop === 0) {
			scrollTop = true;
			// allow to scroll to top again if not all bookings loaded
			if (!this.allBookingsLoadedTop) {
				e.srcElement.scrollTop = 1;
			}
		}
		const scrollProgress = Math.floor(((e.srcElement.scrollTop + e.srcElement.clientHeight) / e.srcElement.scrollHeight) * 100);
		this.scrollProgress = scrollProgress;
		if (scrollProgress > this.loadTrigger && this.isLoadingBookings === false && !this.allBookingsLoadedBottom) {
			this.endAt = false;
			this.getData();
		}
		// if (scrollTop && !this.allBookingsLoadedTop) {
		// 	if (this.bookings[0]) {
		// 		this.cursor = this.bookings[0][this.sortActive];
		// 	}
		// 	this.endAt = true;
		// 	this.getData();
		// }
	}
}
